import React, {Component, useState} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';

import env from './../../../env.js';

import { 
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

import LoadingIndicator from "../../components/LoadingIndicator";

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

function StripeCheckoutForm(props){

  const [state,setState] = useState({
    errorMessage: '',
    email: 'maxtobiasweber@gmail.com',
    payment_method: 'stripe',
  });

  const handleChange = ({error}) => {
    if (error) {
      setState({errorMessage: error.message});
    }
  };

  const onPaymentSuccess = (data) => {
    if(typeof props.onPaymentSuccess !== 'undefined'){
      props.onPaymentSuccess(data);
    }
  }

  const submit = (ev) => {
    ev.preventDefault();
    if (props.stripe) {
      setState({ ...state, errorMessage: '', loading: true});
      //props.stripe.createToken().then((res) => {
      //  console.log(res);
      //  console.log(res.token);
      //  if(typeof res.token !== 'undefined'){
      //    order_init({payment_token: res.token, email: state.email});
      //  }else{
      //    setState({ ...state, errorMessage: res.error.message, loading:false});
      //  }

      //});

      stripecreate_payment_method((data) => {
        order_init(data);
        console.log(data);
      });

    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  }

  const stripecreate_payment_method = (cb) => {
		setState({ ...state, loading: true});

    const cardElement = props.elements.getElement('card');

    props.stripe.createPaymentMethod({
      type:            'card',
      card:            cardElement,
      billing_details: {email: state.email},
    }).then(function(response) {
      if (response.error) {
        // Show error in payment form
        setState({ ...state, errorMessage: response.error.message, loading: false});
      } else {
        cb(response);
      }
    });
  };

  const order_init = (order_data) => {

		setState({ ...state, loading: true});

		fetch(env.site_url + '/order/init_stripe',{
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				order:           state,
				order_id:        props.order_id,
				stripe:          order_data,
				donation_amount: props.donation_amount,
			})
		})
			.then(res => res.json())
			.then(response => {
				if (response.error) {
					//
					// HANDLE STRIPE ERROR
					//
					setState({ ...state, errorMessage: response.message, loading: false});
				} else if(typeof response.requires_action !== 'undefined' && response.requires_action) {
					//
					// HANDLE STRIPE STRONG CUSTOMER AUTH
					//

					props.stripe.handleCardAction(
						response.payment_intent_client_secret
					).then(function(result) {
						if (result.error) {
							//
							// HANDLE STRIPE ERROR
							//

							setState({ ...state, errorMessage: response.error.message, loading: false});
						} else {
							// The card action has been handled
							// The PaymentIntent can be confirmed again on the server
							// If payment intent was confirmed succesfully mark order as paid
							fetch(env.site_url + '/order/stripepaymentconfirm',{
								method: 'POST',
								mode: 'cors',
								headers: {
									'Content-Type': 'application/json'
								},
								body: JSON.stringify({
									payment_intent_id: response.payment_intent_id,
									order_id: response.order_id
								}),
							})
								.then(res => res.json())
								.then(function (response) {
									if(response.success === true){
										//
										// STRIPE PAYMENT SUCCESSFUL
										//
										onPaymentSuccess(response);

										// show thank you message and model download link
									}else{
										//
										// HANDLE STRIPE ERROR
										//
										setState({ ...state, errorMessage: response.message, loading: false});
									}
								});
						}
					});
				}else{
					//
					// STRIPE PAYMENT SUCCESSFUL
					// no further action required
					//
					onPaymentSuccess(response);
					// show thank you message and model download link
				}

				//self.enableCheckoutForm();
			});

  };

  return (
    <div className="checkout">
      <Typography style={{margin:'20px 0'}} variant="body1" component="p">
        Thank You for your support!
      </Typography>
			<Alert severity='info'>
				<Grid
					container
					spacing={0}
				>
					<Grid
						item
						xs={6}
					>
						<img style={{width:'200px',}} src="/images/payment_option_cc.svg" alt="credit card" />
					</Grid>
					<Grid
						item
						xs={6}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}
					>
						<img style={{width:'114px',}} src="/images/stripe_logo.png" alt="stripe logo" />
					</Grid>
				</Grid>
All data entered will be sent encrypted to the PCI-DSS certified (Payment Card Industry Data Security Standard) payment processor stripe only.
At no time will the data be transmitted to a server of Nametag Designer or third parties.
			</Alert>
      <form onSubmit={submit}>
				{ state.loading &&
					<LoadingIndicator />
				}
				{ state.errorMessage &&
						<Grid
							item
							xs={12}
							style={{
								marginTop:'20px',
							}}
						>
									<Alert
										severity="error"
										style={{
											fontSize:'0.8em',
										}}
									>
								{state.errorMessage}
							</Alert>
						</Grid>
				}
				<Grid
					item
					xs={12}
					style={{
						marginTop:'20px',
					}}
				>
					<CardElement {...createOptions()} />
				</Grid>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}
          >
            <Button
              onClick={submit}
              style={{margin:'20px 0'}}
              variant='contained'
            >
              Purchase
            </Button>
					</Grid>
        </Grid>
      </form>
    </div>
  );
}

export default injectStripe(StripeCheckoutForm);
