export default [
  {
    category: 'sans-serif',
    name: 'abeezee',
    subsets: ['latin'],
    variants: ['regular', 'italic']
  },
  {
    category: 'sans-serif',
    name: 'abel',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'abhaya_libre',
    subsets: ['latin', 'latin-ext', 'sinhala'],
    variants: ['regular', '500', '600', '700', '800']
  },
  {
    category: 'display',
    name: 'abril_fatface',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'aclonica',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'acme',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'actor',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'adamina',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'advent_pro',
    subsets: ['greek', 'latin', 'latin-ext'],
    variants: ['100', '200', '300', 'regular', '500', '600', '700']
  },
  {
    category: 'handwriting',
    name: 'aguafina_script',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'akronim',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'aladin',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'alata',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'alatsi',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'aldrich',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'alef',
    subsets: ['hebrew', 'latin'],
    variants: ['regular', '700']
  },
  {
    category: 'serif',
    name: 'alegreya',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      'regular',
      'italic',
      '500',
      '500italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'serif',
    name: 'alegreya_sc',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      'regular',
      'italic',
      '500',
      '500italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'alegreya_sans',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      '100',
      '100italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'alegreya_sans_sc',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      '100',
      '100italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'serif',
    name: 'aleo',
    subsets: ['latin', 'latin-ext'],
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic']
  },
  {
    category: 'handwriting',
    name: 'alex_brush',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'alfa_slab_one',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'alice',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'alike',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'alike_angular',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'allan',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'allerta',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'allerta_stencil',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'allura',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'almarai',
    subsets: ['arabic'],
    variants: ['300', 'regular', '700', '800']
  },
  {
    category: 'serif',
    name: 'almendra',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'display',
    name: 'almendra_display',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'almendra_sc',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'amarante',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'amaranth',
    subsets: ['latin'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'handwriting',
    name: 'amatic_sc',
    subsets: ['cyrillic', 'hebrew', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', '700']
  },
  {
    category: 'serif',
    name: 'amethysta',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'amiko',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular', '600', '700']
  },
  {
    category: 'serif',
    name: 'amiri',
    subsets: ['arabic', 'latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'handwriting',
    name: 'amita',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'anaheim',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'andada',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'andika',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'angkor',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'annie_use_your_telescope',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'monospace',
    name: 'anonymous_pro',
    subsets: ['cyrillic', 'greek', 'latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'sans-serif',
    name: 'antic',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'antic_didone',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'antic_slab',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'anton',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'arapey',
    subsets: ['latin'],
    variants: ['regular', 'italic']
  },
  {
    category: 'display',
    name: 'arbutus',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'arbutus_slab',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'architects_daughter',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'archivo',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'archivo_black',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'archivo_narrow',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'serif',
    name: 'aref_ruqaa',
    subsets: ['arabic', 'latin'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'arima_madurai',
    subsets: ['latin', 'latin-ext', 'tamil', 'vietnamese'],
    variants: ['100', '200', '300', 'regular', '500', '700', '800', '900']
  },
  {
    category: 'sans-serif',
    name: 'arimo',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'hebrew',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'handwriting',
    name: 'arizonia',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'armata',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'arsenal',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'artifika',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'arvo',
    subsets: ['latin'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'sans-serif',
    name: 'arya',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'asap',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'asap_condensed',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'serif',
    name: 'asar',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'asset',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'assistant',
    subsets: ['hebrew', 'latin'],
    variants: ['200', '300', 'regular', '600', '700', '800']
  },
  {
    category: 'display',
    name: 'astloch',
    subsets: ['latin'],
    variants: ['regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'asul',
    subsets: ['latin'],
    variants: ['regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'athiti',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: ['200', '300', 'regular', '500', '600', '700']
  },
  {
    category: 'display',
    name: 'atma',
    subsets: ['bengali', 'latin', 'latin-ext'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'display',
    name: 'atomic_age',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'aubrey',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'audiowide',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'autour_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'average',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'average_sans',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'averia_gruesa_libre',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'averia_libre',
    subsets: ['latin'],
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic']
  },
  {
    category: 'display',
    name: 'averia_sans_libre',
    subsets: ['latin'],
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic']
  },
  {
    category: 'display',
    name: 'averia_serif_libre',
    subsets: ['latin'],
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic']
  },
  {
    category: 'sans-serif',
    name: 'b612',
    subsets: ['latin'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'monospace',
    name: 'b612_mono',
    subsets: ['latin'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'handwriting',
    name: 'bad_script',
    subsets: ['cyrillic', 'latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'bahiana',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'bahianita',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'bai_jamjuree',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'display',
    name: 'baloo_2',
    subsets: ['devanagari', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', '500', '600', '700', '800']
  },
  {
    category: 'display',
    name: 'baloo_bhai_2',
    subsets: ['gujarati', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', '500', '600', '700', '800']
  },
  {
    category: 'display',
    name: 'baloo_bhaina_2',
    subsets: ['latin', 'latin-ext', 'oriya', 'vietnamese'],
    variants: ['regular', '500', '600', '700', '800']
  },
  {
    category: 'display',
    name: 'baloo_chettan_2',
    subsets: ['latin', 'latin-ext', 'malayalam', 'vietnamese'],
    variants: ['regular', '500', '600', '700', '800']
  },
  {
    category: 'display',
    name: 'baloo_da_2',
    subsets: ['bengali', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', '500', '600', '700', '800']
  },
  {
    category: 'display',
    name: 'baloo_paaji_2',
    subsets: ['gurmukhi', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', '500', '600', '700', '800']
  },
  {
    category: 'display',
    name: 'baloo_tamma_2',
    subsets: ['kannada', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', '500', '600', '700', '800']
  },
  {
    category: 'display',
    name: 'baloo_tammudu_2',
    subsets: ['latin', 'latin-ext', 'telugu', 'vietnamese'],
    variants: ['regular', '500', '600', '700', '800']
  },
  {
    category: 'display',
    name: 'baloo_thambi_2',
    subsets: ['latin', 'latin-ext', 'tamil', 'vietnamese'],
    variants: ['regular', '500', '600', '700', '800']
  },
  {
    category: 'display',
    name: 'balsamiq_sans',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'balthazar',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'bangers',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'barlow',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'barlow_condensed',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'barlow_semi_condensed',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'display',
    name: 'barriecito',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'barrio',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'basic',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'baskervville',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic']
  },
  {
    category: 'display',
    name: 'battambang',
    subsets: ['khmer'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'baumans',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'bayon',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'be_vietnam',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '100italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic'
    ]
  },
  {
    category: 'display',
    name: 'bebas_neue',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'belgrano',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'bellefair',
    subsets: ['hebrew', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'belleza',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'bellota',
    subsets: ['cyrillic', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic']
  },
  {
    category: 'display',
    name: 'bellota_text',
    subsets: ['cyrillic', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic']
  },
  {
    category: 'sans-serif',
    name: 'benchnine',
    subsets: ['latin', 'latin-ext'],
    variants: ['300', 'regular', '700']
  },
  {
    category: 'serif',
    name: 'bentham',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'berkshire_swash',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'beth_ellen',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'bevan',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'big_shoulders_display',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['100', '300', 'regular', '500', '600', '700', '800', '900']
  },
  {
    category: 'display',
    name: 'big_shoulders_text',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['100', '300', 'regular', '500', '600', '700', '800', '900']
  },
  {
    category: 'display',
    name: 'bigelow_rules',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'bigshot_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'bilbo',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'bilbo_swash_caps',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'biorhyme',
    subsets: ['latin', 'latin-ext'],
    variants: ['200', '300', 'regular', '700', '800']
  },
  {
    category: 'serif',
    name: 'biorhyme_expanded',
    subsets: ['latin', 'latin-ext'],
    variants: ['200', '300', 'regular', '700', '800']
  },
  {
    category: 'sans-serif',
    name: 'biryani',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['200', '300', 'regular', '600', '700', '800', '900']
  },
  {
    category: 'serif',
    name: 'bitter',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'black_and_white_picture',
    subsets: ['korean', 'latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'black_han_sans',
    subsets: ['korean', 'latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'black_ops_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'blinker',
    subsets: ['latin', 'latin-ext'],
    variants: ['100', '200', '300', 'regular', '600', '700', '800', '900']
  },
  {
    category: 'display',
    name: 'bokor',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'bonbon',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'boogaloo',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'bowlby_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'bowlby_one_sc',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'brawler',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'bree_serif',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'bubblegum_sans',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'bubbler_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'buda',
    subsets: ['latin'],
    variants: ['300']
  },
  {
    category: 'serif',
    name: 'buenard',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'bungee',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'bungee_hairline',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'bungee_inline',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'bungee_outline',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'bungee_shade',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'butcherman',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'butterfly_kids',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'cabin',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'cabin_condensed',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', '500', '600', '700']
  },
  {
    category: 'display',
    name: 'cabin_sketch',
    subsets: ['latin'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'caesar_dressing',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'cagliostro',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'cairo',
    subsets: ['arabic', 'latin', 'latin-ext'],
    variants: ['200', '300', 'regular', '600', '700', '900']
  },
  {
    category: 'serif',
    name: 'caladea',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'display',
    name: 'calistoga',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'calligraffitti',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'cambay',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'cambo',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'candal',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'cantarell',
    subsets: ['latin'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'cantata_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'cantora_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'capriola',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'cardo',
    subsets: ['greek', 'greek-ext', 'latin', 'latin-ext'],
    variants: ['regular', 'italic', '700']
  },
  {
    category: 'sans-serif',
    name: 'carme',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'carrois_gothic',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'carrois_gothic_sc',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'carter_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'catamaran',
    subsets: ['latin', 'latin-ext', 'tamil'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'serif',
    name: 'caudex',
    subsets: ['greek', 'greek-ext', 'latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'handwriting',
    name: 'caveat',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'handwriting',
    name: 'caveat_brush',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'cedarville_cursive',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'ceviche_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'chakra_petch',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: [
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'changa',
    subsets: ['arabic', 'latin', 'latin-ext'],
    variants: ['200', '300', 'regular', '500', '600', '700', '800']
  },
  {
    category: 'display',
    name: 'changa_one',
    subsets: ['latin'],
    variants: ['regular', 'italic']
  },
  {
    category: 'display',
    name: 'chango',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'charm',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: ['regular', '700']
  },
  {
    category: 'handwriting',
    name: 'charmonman',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: ['regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'chathura',
    subsets: ['latin', 'telugu'],
    variants: ['100', '300', 'regular', '700', '800']
  },
  {
    category: 'sans-serif',
    name: 'chau_philomene_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic']
  },
  {
    category: 'display',
    name: 'chela_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'chelsea_market',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'chenla',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'cherry_cream_soda',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'cherry_swash',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'chewy',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'chicle',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'chilanka',
    subsets: ['latin', 'malayalam'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'chivo',
    subsets: ['latin', 'latin-ext'],
    variants: [
      '300',
      '300italic',
      'regular',
      'italic',
      '700',
      '700italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'display',
    name: 'chonburi',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'cinzel',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700', '900']
  },
  {
    category: 'display',
    name: 'cinzel_decorative',
    subsets: ['latin'],
    variants: ['regular', '700', '900']
  },
  {
    category: 'handwriting',
    name: 'clicker_script',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'coda',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '800']
  },
  {
    category: 'sans-serif',
    name: 'coda_caption',
    subsets: ['latin', 'latin-ext'],
    variants: ['800']
  },
  {
    category: 'display',
    name: 'codystar',
    subsets: ['latin', 'latin-ext'],
    variants: ['300', 'regular']
  },
  {
    category: 'display',
    name: 'coiny',
    subsets: ['latin', 'latin-ext', 'tamil', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'combo',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'comfortaa',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'handwriting',
    name: 'comic_neue',
    subsets: ['latin'],
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic']
  },
  {
    category: 'handwriting',
    name: 'coming_soon',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'concert_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'condiment',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'content',
    subsets: ['khmer'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'contrail_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'convergence',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'cookie',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'copse',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'corben',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'serif',
    name: 'cormorant',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: [
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'serif',
    name: 'cormorant_garamond',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: [
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'serif',
    name: 'cormorant_infant',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: [
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'serif',
    name: 'cormorant_sc',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'serif',
    name: 'cormorant_unicase',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'serif',
    name: 'cormorant_upright',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'handwriting',
    name: 'courgette',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'monospace',
    name: 'courier_prime',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'monospace',
    name: 'cousine',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'hebrew',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'coustard',
    subsets: ['latin'],
    variants: ['regular', '900']
  },
  {
    category: 'handwriting',
    name: 'covered_by_your_grace',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'crafty_girls',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'creepster',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'crete_round',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic']
  },
  //{
  //  category: 'serif',
  //  name: 'crimson_pro',
  //  subsets: ['latin', 'latin-ext', 'vietnamese'],
  //  variants: [
  //    '200',
  //    '300',
  //    'regular',
  //    '500',
  //    '600',
  //    '700',
  //    '800',
  //    '900',
  //    '200italic',
  //    '300italic',
  //    'italic',
  //    '500italic',
  //    '600italic',
  //    '700italic',
  //    '800italic',
  //    '900italic'
  //  ]
  //},
  {
    category: 'serif',
    name: 'crimson_text',
    subsets: ['latin'],
    variants: ['regular', 'italic', '600', '600italic', '700', '700italic']
  },
  {
    category: 'display',
    name: 'croissant_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'crushed',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'cuprum',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'display',
    name: 'cute_font',
    subsets: ['korean', 'latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'cutive',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'monospace',
    name: 'cutive_mono',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'monospace',
    name: 'dm_mono',
    subsets: ['latin', 'latin-ext'],
    variants: ['300', '300italic', 'regular', 'italic', '500', '500italic']
  },
  {
    category: 'sans-serif',
    name: 'dm_sans',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '500', '500italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'dm_serif_display',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic']
  },
  {
    category: 'serif',
    name: 'dm_serif_text',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic']
  },
  {
    category: 'handwriting',
    name: 'damion',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'dancing_script',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', '500', '600', '700']
  },
  {
    category: 'display',
    name: 'dangrek',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'darker_grotesque',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['300', 'regular', '500', '600', '700', '800', '900']
  },
  {
    category: 'serif',
    name: 'david_libre',
    subsets: ['hebrew', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', '500', '700']
  },
  {
    category: 'handwriting',
    name: 'dawning_of_a_new_day',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'days_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'dekko',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'delius',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'delius_swash_caps',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'delius_unicase',
    subsets: ['latin'],
    variants: ['regular', '700']
  },
  {
    category: 'serif',
    name: 'della_respira',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'denk_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'devonshire',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'dhurjati',
    subsets: ['latin', 'telugu'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'didact_gothic',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext'
    ],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'diplomata',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'diplomata_sc',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'do_hyeon',
    subsets: ['korean', 'latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'dokdo',
    subsets: ['korean', 'latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'domine',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'serif',
    name: 'donegal_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'doppio_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'dorsa',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'dosis',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['200', '300', 'regular', '500', '600', '700', '800']
  },
  {
    category: 'handwriting',
    name: 'dr_sugiyama',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'duru_sans',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'dynalight',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'eb_garamond',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      'regular',
      '500',
      '600',
      '700',
      '800',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic'
    ]
  },
  {
    category: 'handwriting',
    name: 'eagle_lake',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'east_sea_dokdo',
    subsets: ['korean', 'latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'eater',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'economica',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'eczar',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular', '500', '600', '700', '800']
  },
  {
    category: 'sans-serif',
    name: 'el_messiri',
    subsets: ['arabic', 'cyrillic', 'latin'],
    variants: ['regular', '500', '600', '700']
  },
  {
    category: 'sans-serif',
    name: 'electrolize',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'elsie',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '900']
  },
  {
    category: 'display',
    name: 'elsie_swash_caps',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '900']
  },
  {
    category: 'display',
    name: 'emblema_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'emilys_candy',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'encode_sans',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'sans-serif',
    name: 'encode_sans_condensed',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'sans-serif',
    name: 'encode_sans_expanded',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'sans-serif',
    name: 'encode_sans_semi_condensed',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'sans-serif',
    name: 'encode_sans_semi_expanded',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'handwriting',
    name: 'engagement',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'englebert',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'enriqueta',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '500', '600', '700']
  },
  {
    category: 'sans-serif',
    name: 'epilogue',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic'
    ]
  },
  {
    category: 'display',
    name: 'erica_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'esteban',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'euphoria_script',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'ewert',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'exo',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic'
    ]
  },
  //{
  //  category: 'sans-serif',
  //  name: 'exo_2',
  //  subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
  //  variants: [
  //    '100',
  //    '200',
  //    '300',
  //    'regular',
  //    '500',
  //    '600',
  //    '700',
  //    '800',
  //    '900',
  //    '100italic',
  //    '200italic',
  //    '300italic',
  //    'italic',
  //    '500italic',
  //    '600italic',
  //    '700italic',
  //    '800italic',
  //    '900italic'
  //  ]
  //},
  {
    category: 'display',
    name: 'expletus_sans',
    subsets: ['latin'],
    variants: [
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'fahkwang',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'serif',
    name: 'fanwood_text',
    subsets: ['latin'],
    variants: ['regular', 'italic']
  },
  {
    category: 'sans-serif',
    name: 'farro',
    subsets: ['latin', 'latin-ext'],
    variants: ['300', 'regular', '500', '700']
  },
  {
    category: 'display',
    name: 'farsan',
    subsets: ['gujarati', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'fascinate',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'fascinate_inline',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'faster_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'fasthand',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'fauna_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'faustina',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      'regular',
      '500',
      '600',
      '700',
      'italic',
      '500italic',
      '600italic',
      '700italic'
    ]
  },
  {
    category: 'display',
    name: 'federant',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'federo',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'felipa',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'fenix',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'finger_paint',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'monospace',
    name: 'fira_code',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext'
    ],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'monospace',
    name: 'fira_mono',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext'
    ],
    variants: ['regular', '500', '700']
  },
  {
    category: 'sans-serif',
    name: 'fira_sans',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'fira_sans_condensed',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'fira_sans_extra_condensed',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'fjalla_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'fjord_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'flamenco',
    subsets: ['latin'],
    variants: ['300', 'regular']
  },
  {
    category: 'display',
    name: 'flavors',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'fondamento',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic']
  },
  {
    category: 'display',
    name: 'fontdiner_swanky',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'forum',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'francois_one',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'frank_ruhl_libre',
    subsets: ['hebrew', 'latin', 'latin-ext'],
    variants: ['300', 'regular', '500', '700', '900']
  },
  {
    category: 'display',
    name: 'freckle_face',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  //{
  //  "category": "display",
  //  "name": "fredericka_the_great",
  //  "subsets": [
  //    "latin",
  //    "latin-ext"
  //  ],
  //  "variants": [
  //    "regular"
  //  ]
  //},
  {
    category: 'display',
    name: 'fredoka_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'freehand',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'fresca',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'frijole',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'fruktur',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'fugaz_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'gfs_didot',
    subsets: ['greek'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'gfs_neohellenic',
    subsets: ['greek'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'gabriela',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'gaegu',
    subsets: ['korean', 'latin'],
    variants: ['300', 'regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'gafata',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'galada',
    subsets: ['bengali', 'latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'galdeano',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'galindo',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'gamja_flower',
    subsets: ['korean', 'latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'gayathri',
    subsets: ['latin', 'malayalam'],
    variants: ['100', 'regular', '700']
  },
  {
    category: 'serif',
    name: 'gelasio',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'serif',
    name: 'gentium_basic',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'gentium_book_basic',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'sans-serif',
    name: 'geo',
    subsets: ['latin'],
    variants: ['regular', 'italic']
  },
  {
    category: 'display',
    name: 'geostar',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'geostar_fill',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'germania_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'gidugu',
    subsets: ['latin', 'telugu'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'gilda_display',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'girassol',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'give_you_glory',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'glass_antiqua',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'glegoo',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'handwriting',
    name: 'gloria_hallelujah',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'goblin_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'gochi_hand',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'gorditas',
    subsets: ['latin'],
    variants: ['regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'gothic_a1',
    subsets: ['korean', 'latin'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'sans-serif',
    name: 'gotu',
    subsets: ['devanagari', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'goudy_bookletter_1911',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'graduate',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'grand_hotel',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'gravitas_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'great_vibes',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'grenze',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'display',
    name: 'grenze_gotisch',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'display',
    name: 'griffy',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'gruppo',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'gudea',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '700']
  },
  {
    category: 'display',
    name: 'gugi',
    subsets: ['korean', 'latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'gupter',
    subsets: ['latin'],
    variants: ['regular', '500', '700']
  },
  {
    category: 'serif',
    name: 'gurajada',
    subsets: ['latin', 'telugu'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'habibi',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'halant',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'sans-serif',
    name: 'hammersmith_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'hanalei',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'hanalei_fill',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'handlee',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'hanuman',
    subsets: ['khmer'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'happy_monkey',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'harmattan',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'serif',
    name: 'headland_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'heebo',
    subsets: ['hebrew', 'latin'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'display',
    name: 'henny_penny',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'hepta_slab',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'handwriting',
    name: 'herr_von_muellerhoff',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'hi_melody',
    subsets: ['korean', 'latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'hind',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'sans-serif',
    name: 'hind_guntur',
    subsets: ['latin', 'latin-ext', 'telugu'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'sans-serif',
    name: 'hind_madurai',
    subsets: ['latin', 'latin-ext', 'tamil'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'sans-serif',
    name: 'hind_siliguri',
    subsets: ['bengali', 'latin', 'latin-ext'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'sans-serif',
    name: 'hind_vadodara',
    subsets: ['gujarati', 'latin', 'latin-ext'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'serif',
    name: 'holtwood_one_sc',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'homemade_apple',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'homenaje',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'monospace',
    name: 'ibm_plex_mono',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'ibm_plex_sans',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'ibm_plex_sans_condensed',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'serif',
    name: 'ibm_plex_serif',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'serif',
    name: 'im_fell_dw_pica',
    subsets: ['latin'],
    variants: ['regular', 'italic']
  },
  {
    category: 'serif',
    name: 'im_fell_dw_pica_sc',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'im_fell_double_pica',
    subsets: ['latin'],
    variants: ['regular', 'italic']
  },
  {
    category: 'serif',
    name: 'im_fell_double_pica_sc',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'im_fell_english',
    subsets: ['latin'],
    variants: ['regular', 'italic']
  },
  {
    category: 'serif',
    name: 'im_fell_english_sc',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'im_fell_french_canon',
    subsets: ['latin'],
    variants: ['regular', 'italic']
  },
  {
    category: 'serif',
    name: 'im_fell_french_canon_sc',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'im_fell_great_primer',
    subsets: ['latin'],
    variants: ['regular', 'italic']
  },
  {
    category: 'serif',
    name: 'im_fell_great_primer_sc',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'ibarra_real_nova',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '600', '600italic', '700', '700italic']
  },
  {
    category: 'display',
    name: 'iceberg',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'iceland',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'imprima',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'monospace',
    name: 'inconsolata',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['200', '300', 'regular', '500', '600', '700', '800', '900']
  },
  {
    category: 'sans-serif',
    name: 'inder',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'indie_flower',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'inika',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'serif',
    name: 'inknut_antiqua',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['300', 'regular', '500', '600', '700', '800', '900']
  },
  {
    category: 'sans-serif',
    name: 'inria_sans',
    subsets: ['latin', 'latin-ext'],
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'inria_serif',
    subsets: ['latin', 'latin-ext'],
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic']
  },
  {
    category: 'sans-serif',
    name: 'inter',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'display',
    name: 'irish_grover',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'istok_web',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'italiana',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'italianno',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'itim',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'jacques_francois',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'jacques_francois_shadow',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'jaldi',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'handwriting',
    name: 'jim_nightshade',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'jockey_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'jolly_lodger',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'jomhuria',
    subsets: ['arabic', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'jomolhari',
    subsets: ['latin', 'tibetan'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'josefin_sans',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic'
    ]
  },
  {
    category: 'serif',
    name: 'josefin_slab',
    subsets: ['latin'],
    variants: [
      '100',
      '100italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'jost',
    subsets: ['cyrillic', 'latin', 'latin-ext'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic'
    ]
  },
  {
    category: 'display',
    name: 'joti_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'jua',
    subsets: ['korean', 'latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'judson',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', 'italic', '700']
  },
  {
    category: 'handwriting',
    name: 'julee',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'julius_sans_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'junge',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'jura',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'handwriting',
    name: 'just_another_hand',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'just_me_again_down_here',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'k2d',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic'
    ]
  },
  {
    category: 'serif',
    name: 'kadwa',
    subsets: ['devanagari', 'latin'],
    variants: ['regular', '700']
  },
  {
    category: 'handwriting',
    name: 'kalam',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['300', 'regular', '700']
  },
  {
    category: 'serif',
    name: 'kameron',
    subsets: ['latin'],
    variants: ['regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'kanit',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'kantumruy',
    subsets: ['khmer'],
    variants: ['300', 'regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'karla',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'karma',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'display',
    name: 'katibeh',
    subsets: ['arabic', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'kaushan_script',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'kavivanar',
    subsets: ['latin', 'latin-ext', 'tamil'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'kavoon',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'kdam_thmor',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'keania_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'kelly_slab',
    subsets: ['cyrillic', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'kenia',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'khand',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'display',
    name: 'khmer',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'khula',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['300', 'regular', '600', '700', '800']
  },
  {
    category: 'display',
    name: 'kirang_haerang',
    subsets: ['korean', 'latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'kite_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'knewave',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'koho',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'kodchasan',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'kosugi',
    subsets: ['cyrillic', 'japanese', 'latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'kosugi_maru',
    subsets: ['cyrillic', 'japanese', 'latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'kotta_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'koulen',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'kranky',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'kreon',
    subsets: ['latin', 'latin-ext'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'handwriting',
    name: 'kristi',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'krona_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'krub',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'kulim_park',
    subsets: ['latin', 'latin-ext'],
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'display',
    name: 'kumar_one',
    subsets: ['gujarati', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'kumar_one_outline',
    subsets: ['gujarati', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'kurale',
    subsets: ['cyrillic', 'cyrillic-ext', 'devanagari', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'la_belle_aurore',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'lacquer',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'laila',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'handwriting',
    name: 'lakki_reddy',
    subsets: ['latin', 'telugu'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'lalezar',
    subsets: ['arabic', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'lancelot',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'lateef',
    subsets: ['arabic', 'latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'lato',
    subsets: ['latin', 'latin-ext'],
    variants: [
      '100',
      '100italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '700',
      '700italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'handwriting',
    name: 'league_script',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'leckerli_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'ledger',
    subsets: ['cyrillic', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'lekton',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '700']
  },
  {
    category: 'display',
    name: 'lemon',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'lemonada',
    subsets: ['arabic', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'sans-serif',
    name: 'lexend_deca',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'lexend_exa',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'lexend_giga',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'lexend_mega',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'lexend_peta',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'lexend_tera',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'lexend_zetta',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'libre_barcode_128',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'libre_barcode_128_text',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'libre_barcode_39',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'libre_barcode_39_extended',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'libre_barcode_39_extended_text',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'libre_barcode_39_text',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'libre_baskerville',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '700']
  },
  {
    category: 'serif',
    name: 'libre_caslon_display',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'libre_caslon_text',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '700']
  },
  {
    category: 'sans-serif',
    name: 'libre_franklin',
    subsets: ['latin', 'latin-ext'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'display',
    name: 'life_savers',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700', '800']
  },
  {
    category: 'display',
    name: 'lilita_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'lily_script_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'limelight',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'linden_hill',
    subsets: ['latin'],
    variants: ['regular', 'italic']
  },
  {
    category: 'serif',
    name: 'literata',
    subsets: [
      'cyrillic',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      'regular',
      '500',
      '600',
      '700',
      'italic',
      '500italic',
      '600italic',
      '700italic'
    ]
  },
  {
    category: 'handwriting',
    name: 'liu_jian_mao_cao',
    subsets: ['chinese-simplified', 'latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'livvic',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'display',
    name: 'lobster',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'lobster_two',
    subsets: ['latin'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'display',
    name: 'londrina_outline',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'londrina_shadow',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'londrina_sketch',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'londrina_solid',
    subsets: ['latin'],
    variants: ['100', '300', 'regular', '900']
  },
  {
    category: 'handwriting',
    name: 'long_cang',
    subsets: ['chinese-simplified', 'latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'lora',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: [
      'regular',
      '500',
      '600',
      '700',
      'italic',
      '500italic',
      '600italic',
      '700italic'
    ]
  },
  {
    category: 'display',
    name: 'love_ya_like_a_sister',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'loved_by_the_king',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'lovers_quarrel',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'luckiest_guy',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'lusitana',
    subsets: ['latin'],
    variants: ['regular', '700']
  },
  {
    category: 'serif',
    name: 'lustria',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'm_plus_1p',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'hebrew',
      'japanese',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: ['100', '300', 'regular', '500', '700', '800', '900']
  },
  {
    category: 'sans-serif',
    name: 'm_plus_rounded_1c',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'hebrew',
      'japanese',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: ['100', '300', 'regular', '500', '700', '800', '900']
  },
  {
    category: 'handwriting',
    name: 'ma_shan_zheng',
    subsets: ['chinese-simplified', 'latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'macondo',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'macondo_swash_caps',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'mada',
    subsets: ['arabic', 'latin'],
    variants: ['200', '300', 'regular', '500', '600', '700', '900']
  },
  {
    category: 'sans-serif',
    name: 'magra',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'maiden_orange',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'maitree',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: ['200', '300', 'regular', '500', '600', '700']
  },
  {
    category: 'monospace',
    name: 'major_mono_display',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'mako',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'mali',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'mallanna',
    subsets: ['latin', 'telugu'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'mandali',
    subsets: ['latin', 'telugu'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'manjari',
    subsets: ['latin', 'malayalam'],
    variants: ['100', 'regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'manrope',
    subsets: ['cyrillic', 'greek', 'latin', 'latin-ext'],
    variants: ['200', '300', 'regular', '500', '600', '700', '800']
  },
  {
    category: 'handwriting',
    name: 'mansalva',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'manuale',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      'regular',
      '500',
      '600',
      '700',
      'italic',
      '500italic',
      '600italic',
      '700italic'
    ]
  },
  {
    category: 'serif',
    name: 'marcellus',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'marcellus_sc',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'marck_script',
    subsets: ['cyrillic', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'margarine',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'markazi_text',
    subsets: ['arabic', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', '500', '600', '700']
  },
  {
    category: 'serif',
    name: 'marko_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'marmelad',
    subsets: ['cyrillic', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'martel',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['200', '300', 'regular', '600', '700', '800', '900']
  },
  {
    category: 'sans-serif',
    name: 'martel_sans',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['200', '300', 'regular', '600', '700', '800', '900']
  },
  {
    category: 'sans-serif',
    name: 'marvel',
    subsets: ['latin'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'mate',
    subsets: ['latin'],
    variants: ['regular', 'italic']
  },
  {
    category: 'serif',
    name: 'mate_sc',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'maven_pro',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', '500', '600', '700', '800', '900']
  },
  {
    category: 'display',
    name: 'mclaren',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'meddon',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'medievalsharp',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'medula_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'meera_inimai',
    subsets: ['latin', 'tamil'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'megrim',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'meie_script',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'merienda',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'handwriting',
    name: 'merienda_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'merriweather',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: [
      '300',
      '300italic',
      'regular',
      'italic',
      '700',
      '700italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'merriweather_sans',
    subsets: ['latin', 'latin-ext'],
    variants: [
      '300',
      '300italic',
      'regular',
      'italic',
      '700',
      '700italic',
      '800',
      '800italic'
    ]
  },
  {
    category: 'display',
    name: 'metal',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'metal_mania',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'metamorphous',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'metrophobic',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'michroma',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'milonga',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'miltonian',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'miltonian_tattoo',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'mina',
    subsets: ['bengali', 'latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'miniver',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'miriam_libre',
    subsets: ['hebrew', 'latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'mirza',
    subsets: ['arabic', 'latin', 'latin-ext'],
    variants: ['regular', '500', '600', '700']
  },
  {
    category: 'handwriting',
    name: 'miss_fajardose',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'mitr',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: ['200', '300', 'regular', '500', '600', '700']
  },
  {
    category: 'display',
    name: 'modak',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'modern_antiqua',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'mogra',
    subsets: ['gujarati', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'molengo',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'molle',
    subsets: ['latin', 'latin-ext'],
    variants: ['italic']
  },
  {
    category: 'sans-serif',
    name: 'monda',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'monofett',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'monoton',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'monsieur_la_doulaise',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'montaga',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'montez',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'montserrat',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'montserrat_alternates',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'montserrat_subrayada',
    subsets: ['latin'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'moul',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'moulpali',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'mountains_of_christmas',
    subsets: ['latin'],
    variants: ['regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'mouse_memoirs',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'mr_bedfort',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'mr_dafoe',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'mr_de_haviland',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'mrs_saint_delafield',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'mrs_sheppards',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'mukta',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['200', '300', 'regular', '500', '600', '700', '800']
  },
  {
    category: 'sans-serif',
    name: 'mukta_mahee',
    subsets: ['gurmukhi', 'latin', 'latin-ext'],
    variants: ['200', '300', 'regular', '500', '600', '700', '800']
  },
  {
    category: 'sans-serif',
    name: 'mukta_malar',
    subsets: ['latin', 'latin-ext', 'tamil'],
    variants: ['200', '300', 'regular', '500', '600', '700', '800']
  },
  {
    category: 'sans-serif',
    name: 'mukta_vaani',
    subsets: ['gujarati', 'latin', 'latin-ext'],
    variants: ['200', '300', 'regular', '500', '600', '700', '800']
  },
  {
    category: 'sans-serif',
    name: 'mulish',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic'
    ]
  },
  {
    category: 'display',
    name: 'museomoderno',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'display',
    name: 'mystery_quest',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'ntr',
    subsets: ['latin', 'telugu'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'nanum_brush_script',
    subsets: ['korean', 'latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'nanum_gothic',
    subsets: ['korean', 'latin'],
    variants: ['regular', '700', '800']
  },
  {
    category: 'monospace',
    name: 'nanum_gothic_coding',
    subsets: ['korean', 'latin'],
    variants: ['regular', '700']
  },
  {
    category: 'serif',
    name: 'nanum_myeongjo',
    subsets: ['korean', 'latin'],
    variants: ['regular', '700', '800']
  },
  {
    category: 'handwriting',
    name: 'nanum_pen_script',
    subsets: ['korean', 'latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'neucha',
    subsets: ['cyrillic', 'latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'neuton',
    subsets: ['latin', 'latin-ext'],
    variants: ['200', '300', 'regular', 'italic', '700', '800']
  },
  {
    category: 'display',
    name: 'new_rocker',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'news_cycle',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'handwriting',
    name: 'niconne',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'niramit',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'display',
    name: 'nixie_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'nobile',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '500', '500italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'nokora',
    subsets: ['khmer'],
    variants: ['regular', '700']
  },
  {
    category: 'handwriting',
    name: 'norican',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'nosifer',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'notable',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'nothing_you_could_do',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'noticia_text',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'sans-serif',
    name: 'noto_sans',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'devanagari',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'sans-serif',
    name: 'noto_sans_hk',
    subsets: ['chinese-hongkong', 'latin'],
    variants: ['100', '300', 'regular', '500', '700', '900']
  },
  {
    category: 'sans-serif',
    name: 'noto_sans_jp',
    subsets: ['japanese', 'latin'],
    variants: ['100', '300', 'regular', '500', '700', '900']
  },
  {
    category: 'sans-serif',
    name: 'noto_sans_kr',
    subsets: ['korean', 'latin'],
    variants: ['100', '300', 'regular', '500', '700', '900']
  },
  {
    category: 'sans-serif',
    name: 'noto_sans_sc',
    subsets: ['chinese-simplified', 'latin'],
    variants: ['100', '300', 'regular', '500', '700', '900']
  },
  {
    category: 'sans-serif',
    name: 'noto_sans_tc',
    subsets: ['chinese-traditional', 'latin'],
    variants: ['100', '300', 'regular', '500', '700', '900']
  },
  {
    category: 'serif',
    name: 'noto_serif',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'noto_serif_jp',
    subsets: ['japanese', 'latin'],
    variants: ['200', '300', 'regular', '500', '600', '700', '900']
  },
  {
    category: 'serif',
    name: 'noto_serif_kr',
    subsets: ['korean', 'latin'],
    variants: ['200', '300', 'regular', '500', '600', '700', '900']
  },
  {
    category: 'serif',
    name: 'noto_serif_sc',
    subsets: ['chinese-simplified', 'latin'],
    variants: ['200', '300', 'regular', '500', '600', '700', '900']
  },
  {
    category: 'serif',
    name: 'noto_serif_tc',
    subsets: ['chinese-traditional', 'latin'],
    variants: ['200', '300', 'regular', '500', '600', '700', '900']
  },
  {
    category: 'display',
    name: 'nova_cut',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'nova_flat',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'monospace',
    name: 'nova_mono',
    subsets: ['greek', 'latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'nova_oval',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'nova_round',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'nova_script',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'nova_slim',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'nova_square',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'numans',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'nunito',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'nunito_sans',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'display',
    name: 'odibee_sans',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'odor_mean_chey',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'offside',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'old_standard_tt',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', 'italic', '700']
  },
  {
    category: 'display',
    name: 'oldenburg',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'oleo_script',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'oleo_script_swash_caps',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'open_sans',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      '300',
      '300italic',
      'regular',
      'italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'open_sans_condensed',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: ['300', '300italic', '700']
  },
  {
    category: 'serif',
    name: 'oranienbaum',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'orbitron',
    subsets: ['latin'],
    variants: ['regular', '500', '600', '700', '800', '900']
  },
  {
    category: 'display',
    name: 'oregano',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic']
  },
  {
    category: 'sans-serif',
    name: 'orienta',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'original_surfer',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'oswald',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['200', '300', 'regular', '500', '600', '700']
  },
  {
    category: 'handwriting',
    name: 'over_the_rainbow',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'overlock',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic', '900', '900italic']
  },
  {
    category: 'display',
    name: 'overlock_sc',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'overpass',
    subsets: ['latin', 'latin-ext'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'monospace',
    name: 'overpass_mono',
    subsets: ['latin', 'latin-ext'],
    variants: ['300', 'regular', '600', '700']
  },
  {
    category: 'serif',
    name: 'ovo',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'oxanium',
    subsets: ['latin', 'latin-ext'],
    variants: ['200', '300', 'regular', '500', '600', '700', '800']
  },
  {
    category: 'sans-serif',
    name: 'oxygen',
    subsets: ['latin', 'latin-ext'],
    variants: ['300', 'regular', '700']
  },
  {
    category: 'monospace',
    name: 'oxygen_mono',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'monospace',
    name: 'pt_mono',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'pt_sans',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'sans-serif',
    name: 'pt_sans_caption',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'pt_sans_narrow',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'serif',
    name: 'pt_serif',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'pt_serif_caption',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext'],
    variants: ['regular', 'italic']
  },
  {
    category: 'handwriting',
    name: 'pacifico',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'padauk',
    subsets: ['latin', 'myanmar'],
    variants: ['regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'palanquin',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['100', '200', '300', 'regular', '500', '600', '700']
  },
  {
    category: 'sans-serif',
    name: 'palanquin_dark',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular', '500', '600', '700']
  },
  {
    category: 'handwriting',
    name: 'pangolin',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'paprika',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'parisienne',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'passero_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'passion_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700', '900']
  },
  {
    category: 'sans-serif',
    name: 'pathway_gothic_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'patrick_hand',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'patrick_hand_sc',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'pattaya',
    subsets: ['cyrillic', 'latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'patua_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'pavanam',
    subsets: ['latin', 'latin-ext', 'tamil'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'paytone_one',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'peddana',
    subsets: ['latin', 'telugu'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'peralta',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'permanent_marker',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'petit_formal_script',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'petrona',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'philosopher',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'vietnamese'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'display',
    name: 'piedra',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'pinyon_script',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'pirata_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'plaster',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'play',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'playball',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'playfair_display',
    subsets: ['cyrillic', 'latin', 'latin-ext', 'vietnamese'],
    variants: [
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic'
    ]
  },
  {
    category: 'serif',
    name: 'playfair_display_sc',
    subsets: ['cyrillic', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', 'italic', '700', '700italic', '900', '900italic']
  },
  {
    category: 'serif',
    name: 'podkova',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', '500', '600', '700', '800']
  },
  {
    category: 'display',
    name: 'poiret_one',
    subsets: ['cyrillic', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'poller_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'poly',
    subsets: ['latin'],
    variants: ['regular', 'italic']
  },
  {
    category: 'display',
    name: 'pompiere',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'pontano_sans',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'poor_story',
    subsets: ['korean', 'latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'poppins',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'port_lligat_sans',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'port_lligat_slab',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'pragati_narrow',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'serif',
    name: 'prata',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'preahvihear',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'press_start_2p',
    subsets: ['cyrillic', 'cyrillic-ext', 'greek', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'pridi',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: ['200', '300', 'regular', '500', '600', '700']
  },
  {
    category: 'handwriting',
    name: 'princess_sofia',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'prociono',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'prompt',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'display',
    name: 'prosto_one',
    subsets: ['cyrillic', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'proza_libre',
    subsets: ['latin', 'latin-ext'],
    variants: [
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'public_sans',
    subsets: ['latin', 'latin-ext'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'puritan',
    subsets: ['latin'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'display',
    name: 'purple_purse',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'quando',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'quantico',
    subsets: ['latin'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'quattrocento',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'quattrocento_sans',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'sans-serif',
    name: 'questrial',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'quicksand',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'handwriting',
    name: 'quintessential',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'qwigley',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'racing_sans_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'radley',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic']
  },
  {
    category: 'sans-serif',
    name: 'rajdhani',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'display',
    name: 'rakkas',
    subsets: ['arabic', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'raleway',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic'
    ]
  },
  {
    category: 'display',
    name: 'raleway_dots',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'ramabhadra',
    subsets: ['latin', 'telugu'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'ramaraja',
    subsets: ['latin', 'telugu'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'rambla',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'display',
    name: 'rammetto_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'ranchers',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'rancho',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'ranga',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'serif',
    name: 'rasa',
    subsets: ['gujarati', 'latin', 'latin-ext'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'sans-serif',
    name: 'rationale',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'ravi_prakash',
    subsets: ['latin', 'telugu'],
    variants: ['regular']
  },
  //{
  //  category: 'sans-serif',
  //  name: 'recursive',
  //  subsets: ['latin', 'vietnamese'],
  //  variants: ['300', 'regular', '500', '600', '700', '800', '900']
  //},
  {
    category: 'sans-serif',
    name: 'red_hat_display',
    subsets: ['latin', 'latin-ext'],
    variants: [
      'regular',
      'italic',
      '500',
      '500italic',
      '700',
      '700italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'red_hat_text',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '500', '500italic', '700', '700italic']
  },
  {
    category: 'display',
    name: 'red_rose',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['300', 'regular', '700']
  },
  {
    category: 'handwriting',
    name: 'redressed',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'reem_kufi',
    subsets: ['arabic', 'latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'reenie_beanie',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'revalia',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'rhodium_libre',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'ribeye',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'ribeye_marrow',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'righteous',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'risque',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'roboto',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      '100',
      '100italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '700',
      '700italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'roboto_condensed',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic']
  },
  {
    category: 'monospace',
    name: 'roboto_mono',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic'
    ]
  },
  {
    category: 'serif',
    name: 'roboto_slab',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'handwriting',
    name: 'rochester',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'rock_salt',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'rokkitt',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'handwriting',
    name: 'romanesco',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'ropa_sans',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic']
  },
  {
    category: 'sans-serif',
    name: 'rosario',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '300',
      'regular',
      '500',
      '600',
      '700',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic'
    ]
  },
  {
    category: 'serif',
    name: 'rosarivo',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic']
  },
  {
    category: 'handwriting',
    name: 'rouge_script',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'rowdies',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['300', 'regular', '700']
  },
  {
    category: 'serif',
    name: 'rozha_one',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'rubik',
    subsets: ['cyrillic', 'hebrew', 'latin', 'latin-ext'],
    variants: [
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '700',
      '700italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'rubik_mono_one',
    subsets: ['cyrillic', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'ruda',
    subsets: ['cyrillic', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', '500', '600', '700', '800', '900']
  },
  {
    category: 'serif',
    name: 'rufina',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'handwriting',
    name: 'ruge_boogie',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'ruluko',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'rum_raisin',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'ruslan_display',
    subsets: ['cyrillic', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'russo_one',
    subsets: ['cyrillic', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'ruthie',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'rye',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'sacramento',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'sahitya',
    subsets: ['devanagari', 'latin'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'sail',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'saira',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'sans-serif',
    name: 'saira_condensed',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'sans-serif',
    name: 'saira_extra_condensed',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'sans-serif',
    name: 'saira_semi_condensed',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'display',
    name: 'saira_stencil_one',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'salsa',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'sanchez',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic']
  },
  {
    category: 'display',
    name: 'sancreek',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'sansita',
    subsets: ['latin', 'latin-ext'],
    variants: [
      'regular',
      'italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'sarabun',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'sarala',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'sarina',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'sarpanch',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular', '500', '600', '700', '800', '900']
  },
  {
    category: 'handwriting',
    name: 'satisfy',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'sawarabi_gothic',
    subsets: ['cyrillic', 'japanese', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'sawarabi_mincho',
    subsets: ['japanese', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'scada',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'scheherazade',
    subsets: ['arabic', 'latin'],
    variants: ['regular', '700']
  },
  {
    category: 'handwriting',
    name: 'schoolbell',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'scope_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'seaweed_script',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'secular_one',
    subsets: ['hebrew', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'sedgwick_ave',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'sedgwick_ave_display',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'sen',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700', '800']
  },
  {
    category: 'display',
    name: 'sevillana',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'seymour_one',
    subsets: ['cyrillic', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'shadows_into_light',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'shadows_into_light_two',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'shanti',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'share',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'sans-serif',
    name: 'share_tech',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'monospace',
    name: 'share_tech_mono',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'shojumaru',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'short_stack',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'shrikhand',
    subsets: ['gujarati', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'siemreap',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'sigmar_one',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'signika',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'sans-serif',
    name: 'signika_negative',
    subsets: ['latin', 'latin-ext'],
    variants: ['300', 'regular', '600', '700']
  },
  {
    category: 'display',
    name: 'simonetta',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '900', '900italic']
  },
  {
    category: 'display',
    name: 'single_day',
    subsets: ['korean'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'sintony',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'sirin_stencil',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'six_caps',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'skranji',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'serif',
    name: 'slabo_13px',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'slabo_27px',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'slackey',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'smokum',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'smythe',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'sniglet',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '800']
  },
  {
    category: 'sans-serif',
    name: 'snippet',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'snowburst_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'sofadi_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'sofia',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'solway',
    subsets: ['latin'],
    variants: ['300', 'regular', '500', '700', '800']
  },
  {
    category: 'serif',
    name: 'song_myung',
    subsets: ['korean', 'latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'sonsie_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'sora',
    subsets: ['latin', 'latin-ext'],
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800']
  },
  {
    category: 'serif',
    name: 'sorts_mill_goudy',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic']
  },
  {
    category: 'monospace',
    name: 'source_code_pro',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'source_sans_pro',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'serif',
    name: 'source_serif_pro',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'monospace',
    name: 'space_mono',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'sans-serif',
    name: 'spartan',
    subsets: ['latin', 'latin-ext'],
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900'
    ]
  },
  {
    category: 'display',
    name: 'special_elite',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'spectral',
    subsets: ['cyrillic', 'latin', 'latin-ext', 'vietnamese'],
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic'
    ]
  },
  {
    category: 'serif',
    name: 'spectral_sc',
    subsets: ['cyrillic', 'latin', 'latin-ext', 'vietnamese'],
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic'
    ]
  },
  {
    category: 'display',
    name: 'spicy_rice',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'spinnaker',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'spirax',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'squada_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'sree_krushnadevaraya',
    subsets: ['latin', 'telugu'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'sriracha',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'srisakdi',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'staatliches',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'stalemate',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'stalinist_one',
    subsets: ['cyrillic', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'stardos_stencil',
    subsets: ['latin'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'stint_ultra_condensed',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'stint_ultra_expanded',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'stoke',
    subsets: ['latin', 'latin-ext'],
    variants: ['300', 'regular']
  },
  {
    category: 'sans-serif',
    name: 'strait',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'stylish',
    subsets: ['korean', 'latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'sue_ellen_francisco',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'suez_one',
    subsets: ['hebrew', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'sulphur_point',
    subsets: ['latin', 'latin-ext'],
    variants: ['300', 'regular', '700']
  },
  {
    category: 'serif',
    name: 'sumana',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'sunflower',
    subsets: ['korean', 'latin'],
    variants: ['300', '500', '700']
  },
  {
    category: 'handwriting',
    name: 'sunshiney',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'supermercado_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'sura',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular', '700']
  },
  {
    category: 'serif',
    name: 'suranna',
    subsets: ['latin', 'telugu'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'suravaram',
    subsets: ['latin', 'telugu'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'suwannaphum',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'swanky_and_moo_moo',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'syncopate',
    subsets: ['latin'],
    variants: ['regular', '700']
  },
  {
    category: 'sans-serif',
    name: 'tajawal',
    subsets: ['arabic', 'latin'],
    variants: ['200', '300', 'regular', '500', '700', '800', '900']
  },
  {
    category: 'handwriting',
    name: 'tangerine',
    subsets: ['latin'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'taprom',
    subsets: ['khmer'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'tauri',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'taviraj',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'teko',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'sans-serif',
    name: 'telex',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'tenali_ramakrishna',
    subsets: ['latin', 'telugu'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'tenor_sans',
    subsets: ['cyrillic', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'text_me_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'thasadith',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'handwriting',
    name: 'the_girl_next_door',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'tienne',
    subsets: ['latin'],
    variants: ['regular', '700', '900']
  },
  {
    category: 'handwriting',
    name: 'tillana',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular', '500', '600', '700', '800']
  },
  {
    category: 'sans-serif',
    name: 'timmana',
    subsets: ['latin', 'telugu'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'tinos',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'hebrew',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'display',
    name: 'titan_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'titillium_web',
    subsets: ['latin', 'latin-ext'],
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '900'
    ]
  },
  {
    category: 'sans-serif',
    name: 'tomorrow',
    subsets: ['latin', 'latin-ext'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'display',
    name: 'trade_winds',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'trirong',
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic'
    ]
  },
  {
    category: 'serif',
    name: 'trocchi',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'trochut',
    subsets: ['latin'],
    variants: ['regular', 'italic', '700']
  },
  {
    category: 'serif',
    name: 'trykker',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'tulpen_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'turret_road',
    subsets: ['latin', 'latin-ext'],
    variants: ['200', '300', 'regular', '500', '700', '800']
  },
  {
    category: 'sans-serif',
    name: 'ubuntu',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext'
    ],
    variants: [
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '700',
      '700italic'
    ]
  },
  {
    category: 'sans-serif',
    name: 'ubuntu_condensed',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext'
    ],
    variants: ['regular']
  },
  {
    category: 'monospace',
    name: 'ubuntu_mono',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'greek-ext',
      'latin',
      'latin-ext'
    ],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'ultra',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'uncial_antiqua',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'underdog',
    subsets: ['cyrillic', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'unica_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'unifrakturcook',
    subsets: ['latin'],
    variants: ['700']
  },
  {
    category: 'display',
    name: 'unifrakturmaguntia',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'unkempt',
    subsets: ['latin'],
    variants: ['regular', '700']
  },
  {
    category: 'display',
    name: 'unlock',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'unna',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'monospace',
    name: 'vt323',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'vampiro_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'varela',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'varela_round',
    subsets: ['hebrew', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'varta',
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'display',
    name: 'vast_shadow',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'vesper_libre',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular', '500', '700', '900']
  },
  {
    category: 'display',
    name: 'viaoda_libre',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'vibes',
    subsets: ['arabic', 'latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'vibur',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'vidaloka',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'viga',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'voces',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'volkhov',
    subsets: ['latin'],
    variants: ['regular', 'italic', '700', '700italic']
  },
  {
    category: 'serif',
    name: 'vollkorn',
    subsets: [
      'cyrillic',
      'cyrillic-ext',
      'greek',
      'latin',
      'latin-ext',
      'vietnamese'
    ],
    variants: [
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic'
    ]
  },
  {
    category: 'serif',
    name: 'vollkorn_sc',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular', '600', '700', '900']
  },
  {
    category: 'sans-serif',
    name: 'voltaire',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'waiting_for_the_sunrise',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'wallpoet',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'walter_turncoat',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'warnes',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'wellfleet',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'wendy_one',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'sans-serif',
    name: 'wire_one',
    subsets: ['latin'],
    variants: ['regular']
  },
  //{
  //  category: 'sans-serif',
  //  name: 'work_sans',
  //  subsets: ['latin', 'latin-ext', 'vietnamese'],
  //  variants: [
  //    '100',
  //    '200',
  //    '300',
  //    'regular',
  //    '500',
  //    '600',
  //    '700',
  //    '800',
  //    '900',
  //    '100italic',
  //    '200italic',
  //    '300italic',
  //    'italic',
  //    '500italic',
  //    '600italic',
  //    '700italic',
  //    '800italic',
  //    '900italic'
  //  ]
  //},
  {
    category: 'sans-serif',
    name: 'yanone_kaffeesatz',
    subsets: ['cyrillic', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['200', '300', 'regular', '500', '600', '700']
  },
  {
    category: 'sans-serif',
    name: 'yantramanav',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['100', '300', 'regular', '500', '700', '900']
  },
  {
    category: 'display',
    name: 'yatra_one',
    subsets: ['devanagari', 'latin', 'latin-ext'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'yellowtail',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'yeon_sung',
    subsets: ['korean', 'latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'yeseva_one',
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'yesteryear',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'yrsa',
    subsets: ['latin', 'latin-ext'],
    variants: ['300', 'regular', '500', '600', '700']
  },
  {
    category: 'display',
    name: 'zcool_kuaile',
    subsets: ['chinese-simplified', 'latin'],
    variants: ['regular']
  },
  {
    category: 'display',
    name: 'zcool_qingke_huangyou',
    subsets: ['chinese-simplified', 'latin'],
    variants: ['regular']
  },
  {
    category: 'serif',
    name: 'zcool_xiaowei',
    subsets: ['chinese-simplified', 'latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'zeyada',
    subsets: ['latin'],
    variants: ['regular']
  },
  {
    category: 'handwriting',
    name: 'zhi_mang_xing',
    subsets: ['chinese-simplified', 'latin'],
    variants: ['regular']
  },
  //{
  //  "category": "serif",
  //  "name": "zilla_slab",
  //  "subsets": [
  //    "latin",
  //    "latin-ext"
  //  ],
  //  "variants": [
  //    "300",
  //    "300italic",
  //    "regular",
  //    "italic",
  //    "500",
  //    "500italic",
  //    "600",
  //    "600italic",
  //    "700",
  //    "700italic"
  //  ]
  //},
  {
    category: 'display',
    name: 'zilla_slab_highlight',
    subsets: ['latin', 'latin-ext'],
    variants: ['regular', '700']
  }
];
