import React, {Component, useState, useEffect} from 'react';

import StripeCheckoutForm from '../StripeCheckoutForm';
import PaypalCheckoutForm from '../PaypalCheckoutForm';

import LoadingIndicator from "../LoadingIndicator";

//import Calculation from '../../components/Calculation';
import {Elements, StripeProvider} from 'react-stripe-elements';

import CreditCardIcon from '@material-ui/icons/CreditCard';

import env from './../../../env.js';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';

import RadioGroup from '@material-ui/core/RadioGroup';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';

import { 
  FormControlLabel,
  FormLabel,
  InputAdornment,
  FormControl,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';


const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

function CheckoutDialogValueForValue(props){

  const [state,setState] = useState({
    //show:                    'payment_method_selection',
    show:                      '',
    order_id:                  null,
    payment_method:            'stripe',
    coupon_code:               '',
    coupon_error:              '',
    error_message:             '',
    success_message:           '',
    email:                     '',
    email_valid:               false,
    email_error:               false,
    email_error_message:       '',
    tos:                       false,
    tos_error:                 false,
    loading:                   true,
    donation_amount:           0,
  });

  const [checkout_method, setCheckoutMethod] = useState(null)

  const onChangeCheckoutMethod = (ev, value) => {
    setCheckoutMethod(value);
  }
  const downloadStl = (cb, name) => {
    //console.log(processor);
    window.ojs_processor.clearOutputFile();
    var blob = window.ojs_processor.currentObjectsToBlob();
    var extension = window.ojs_processor.selectedFormatInfo().extension;

    function onDone(data, downloadAttribute, blobMode, noData, blob) {
      window.ojs_processor.hasOutputFile = true;
      window.ojs_processor.downloadOutputFileLink.href = data;
      if (blobMode) {
        window.ojs_processor.outputFileBlobUrl = data;
      } else {
        // FIXME: what to do with this one ?
        // that.outputFileDirEntry = dirEntry // save for later removal
      }
      window.ojs_processor.downloadOutputFileLink.innerHTML = window.ojs_processor.downloadLinkTextForCurrentObject();
      window.ojs_processor.downloadOutputFileLink.setAttribute(
        'download',
        name + '.stl'
      );
      if (noData) {
        window.ojs_processor.downloadOutputFileLink.setAttribute(
          'target',
          '_blank'
        );
      }
      window.ojs_processor.enableItems();

      document.getElementsByClassName('downloadOutputFileLink')[0].click();
      cb();
    }

    if (window.ojs_processor.viewedObject) {
      window.ojs_processor._generateOutputFile(
        extension,
        blob,
        onDone,
        window.ojs_processor
      );
      if (window.ojs_processor.ondownload)
        window.ojs_processor.ondownload(window.ojs_processor);
    }
  };


  useEffect(() => {
    downloadStl(() => {
      props.onUploadReady();
      setState({...state,loading:false});
    });
  },[]);




  useEffect(() => {
    if(props.orderInfo.filename){
      setState({...state,show:'success_message'});
    }
  },[props.orderInfo.filename]);

  const handlePaymentMethodSelection = (val) => {
    setState({...state, payment_method: val,});
  }

  const button_next = () => {
    setState({...state, show:state.payment_method + '_checkout_form'});
  };

  return (
    <Dialog
      maxWidth='sm'
      fullWidth={true}
      aria-labelledby="customized-dialog-title"
      open={true}
      onClose={props.closeDialog}
    >
      <DialogContent dividers>
        { state.loading &&
            <LoadingIndicator />
        }

          <Grid
            container
            spacing={4}
            key={'payment_method_selection_2'}
          >
            <Grid
              item
              xs={12}
            >
              <Paper style={{backgroundColor:'#f1ffde'}}>
                <Typography style={{padding:'20px', textAlign: 'center', fontSize: '1em'}} component="p">
                  <strong>Success!</strong>
                  <br/>
                  <ul style={{marginLeft:'16px', textAlign:'left',}}>
                    <li>Post prints on social media and add a link to www.nametag-designer.com</li>
                    <li>Tell friends about www.nametag-designer.com</li>
                  </ul>
                  <br/>
                  Thank You for supporting www.nametag-designer.com!
                  
                </Typography>
              </Paper>
            </Grid>
          </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default CheckoutDialogValueForValue;
