import React, {ReactDOM, Component, useState, useEffect} from 'react';
//import PayPalButton from "./../PayPalButton";
import { PayPalButton } from "./../react-paypal-button-v2";

import env from './../../../env.js';

import { 
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

import LoadingIndicator from "../LoadingIndicator";

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

function PaypalCheckoutForm(props){

  const [state,setState] = useState({
    errorMessage: '',
    email: 'maxtobiasweber@gmail.com',
    payment_method: 'paypal',
		loading: true,
		paymentSuccessful: false,
  });

  return (
    <div className="checkout">
      <Typography style={{margin:'20px 0'}} variant="body1" component="p">
        Thank You for your support!
      </Typography>
				{ state.loading &&
					<LoadingIndicator />
				}
				{ state.errorMessage &&
						<Grid
							item
							xs={12}
							style={{
								marginTop:'20px',
							}}
						>
									<Alert
										severity="error"
										style={{
											fontSize:'0.8em',
										}}
									>
								{state.errorMessage}
							</Alert>
						</Grid>
				}
				<Grid
					item
					xs={12}
					style={{
						marginTop:'20px',
					}}
				>
					<PayPalButton
						amount={props.amount}
						beforeCreateOrder={() => {
							setState({...state, loading:true});
						}}
						onButtonReady={() => {
							setState({...state, loading:false});
						}}
						onSuccess={(details, data) => {
							setState({...state, loading:true});

							fetch(env.site_url + '/order/confirm_paypal',{
								method: 'POST',
								mode: 'cors',
								headers: {
									'Content-Type': 'application/json'
								},
								body: JSON.stringify({
									order_id:  props.order_id,
									paypal_id: data.orderID,
								})
							})
								.then(res => res.json())
								.then(response => {
									console.log(response);
									if(response.success){
										props.onPaymentSuccess(response);
									}else{
										setState({...state, loading:false, });
									}
								});
							//return fetch("/paypal-transaction-complete", {
							//	method: "post",
							//	body: JSON.stringify({
							//		orderID: data.orderID
							//	})
							//});
						}}
						options={props.options}
						style={{
							height:35
						}}
					/>
				</Grid>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}
          >
					</Grid>
        </Grid>
    </div>
  );
}

export default PaypalCheckoutForm;
