var env = {
  //site_url:'http://localhost:8500/api',
};

switch(document.location.host){
  case 'staging.nametag_designer.com':
    env = {
      env: 'staging',
      site_url:'/api',
      stripe_public_key:"pk_test_tqLCnkeNLxCN6meRKLOH5AIt00eCG8izYQ",
      paypal_client_id: "AUHDC96ftZ_kXAgBytWKDtOP2kUu7RnhG0iXUImunGrBo9x_XDFiCNZqI72uIlJ26B9wWN8SgCck0Mn2"
    };
    break;
  case 'www.nametag-designer.com':
  case 'nametag-designer.com':
    env = {
      env: 'production',
      site_url:'/api',
      stripe_public_key:"pk_live_P8WmuNXpNITmiJFhZ27fNPjG00dTIXi3WF",
      paypal_client_id: "AcIygsCQLrY-wJ7Qd-8__8Z4W5k-3ymtfvNHEKL863gWfNSiSuTIuyA8by5cHNE_u_8JHeoUKO38SOMT"
    };
    break;
  case 'localhost:3000':
  default:
    env = {
      env: 'dev',
      site_url:'http://localhost:8500/api',
      stripe_public_key:"pk_test_tqLCnkeNLxCN6meRKLOH5AIt00eCG8izYQ",
      paypal_client_id: "AUHDC96ftZ_kXAgBytWKDtOP2kUu7RnhG0iXUImunGrBo9x_XDFiCNZqI72uIlJ26B9wWN8SgCck0Mn2"
    };
    break;
}



export default env;
